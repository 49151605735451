import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatment } from '~/domains/common/hooks/useTreatment';

import { BRANDS } from '~/domains/auth/consts';
import type { PLATFORMS } from '~/domains/auth/consts';

export const useGetBranding = (): {
  isLoading: boolean;
  brand?: BRANDS;
  platform?: PLATFORMS;
} => {
  const {
    treatment: { treatment },
    isReady,
  } = useTreatment(FEATURE.ENABLE_UNIFIED_LOGIN);
  const { query } = useRouter();
  const brand = query.brand as BRANDS | undefined;
  const platform = query.platform as PLATFORMS | undefined;
  const isUnifiedLoginEnabled = treatment === 'on';

  const path = usePathname();
  const isAuthPath = [
    '/sign-up',
    '/sign-in',
    '/account/forgot-password',
    '/account/reset-password',
    '/account/confirm-password',
  ].includes(path);

  const uppercaseBrand = brand?.toUpperCase();
  const uppercasePlatform = platform?.toUpperCase();

  if (!isReady) {
    return { isLoading: true, brand: undefined, platform: undefined };
  }
  if (!isAuthPath || !brand || !isUnifiedLoginEnabled) {
    return { isLoading: false, brand: BRANDS.SPLASH, platform: uppercasePlatform as PLATFORMS };
  }
  if (uppercaseBrand === 'RYP') {
    return { isLoading: false, brand: BRANDS.RYP, platform: uppercasePlatform as PLATFORMS };
  }
  if (uppercaseBrand === 'OFP') {
    return { isLoading: false, brand: BRANDS.OFP, platform: uppercasePlatform as PLATFORMS };
  }
  return { isLoading: false, brand: BRANDS.SPLASH, platform: uppercasePlatform as PLATFORMS };
};
