import { Countries } from '~/common/constants/countries.enum';

export const REFRESH_TOKEN_EXPIRATION_TIME = 30;

export const STATE_PROVINCE_CODES = {
  [Countries.US]: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  [Countries.CA]: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'YT'],
};

export enum BRANDS {
  RYP = 'RYP',
  OFP = 'OFP',
  SPLASH = 'SPLASH',
}

export const EO_BRANDS = {
  [BRANDS.RYP]: 'RYP',
  [BRANDS.OFP]: 'OFP',
};

export const BRAND_NAMES = {
  [BRANDS.RYP]: 'RunYourPool',
  [BRANDS.OFP]: 'OfficeFootballPool',
  [BRANDS.SPLASH]: 'Splash',
};

export enum PLATFORMS {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}
